import React from 'react';

export default function TextSlide(props: any) {
  return (
    <li
      data-transition="slotzoom-horizontal"
      data-slotamount={7}
      data-masterspeed={1000}
      data-fsmasterspeed={1000}
    >
      {/* Main image*/}
      <img
        src={props.backgroundUrl}
        alt=""
        data-bgposition="center center"
        data-bgfit="cover"
        data-bgrepeat="no-repeat"
        className="rev-slidebg"
      />
      {/* Layer 1 */}
      <div
        className="slide-title tp-caption tp-resizeme"
        data-x="['middle','middle','middle','middle']"
        data-hoffset="['100','100','100','100']"
        data-y="['middle','middle','middle','middle']"
        data-voffset="['-35','-35','-25','-25']"
        data-fontsize="['50','45','40','40']"
        data-lineheight="['80','75','65','65']"
        data-width="['1000','900','700','780']"
        data-height="none"
        data-whitespace="normal"
        data-transform_idle="o:1;"
        data-transform_in="x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;s:2000;e:Power2.easeInOut;"
        data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
        data-mask_in="x:50px;y:0px;s:inherit;e:inherit;"
        data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
        data-start={500}
        data-splitin="chars"
        data-splitout="none"
        data-responsive_offset="on"
        data-elementdelay="0.05"
      >
        {props.text}
      </div>
    </li>
  );
}
