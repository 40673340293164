import React from 'react';

export default function ImageSlide(props: any) {
  return (
    <li
      data-transition="slotzoom-horizontal"
      data-slotamount={7}
      data-easein="Power3.easeInOut"
      data-easeout="Power3.easeInOut"
      data-masterspeed={1000}
    >
      {/* Main image */}
      <img
        src={props.backgroundUrl}
        alt=""
        data-bgposition="center center"
        data-bgfit="cover"
        data-bgrepeat="no-repeat"
        className="rev-slidebg"
      />
      {/* Layer 1 */}
      <div
        className="slide-title tp-caption tp-resizeme"
        data-x="['middle','middle','middle','middle']"
        data-y="['middle','middle','middle','middle']"
        data-hoffset="['0','50','100','100']"
        data-voffset="['0','0','50','50']"
        data-width="['500','500','600','660']"
        data-height="none"
        data-whitespace="normal"
        data-transform_idle="o:1;"
        data-transform_in="sX:0;sY:0;rX:360deg;s:2000;e:Power2.easeInOut;"
        data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;"
        data-mask_in="x:0px;y:0px;s:inherit;e:inherit;"
        data-mask_out="x:inherit;y:inherit;s:inherit;e:inherit;"
        data-start={500}
        data-responsive_offset="on"
        data-elementdelay="0.05"
      >
        <img className="slide-fg-image" src={props.imageUrl} alt="" />
      </div>
    </li>
  );
}
