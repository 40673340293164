/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface SEOProps {
  meta?: Array<
    | { name: string; content: any; property?: undefined }
    | { property: string; content: any; name?: undefined }
  >;
}

function SEO({ meta = [] }: SEOProps) {
  const data = useStaticQuery(
    graphql`
      query {
        sanityMetadata {
          title
          description
          url
          facebook_app_id
          image {
            asset {
              url
            }
          }
        }
      }
    `,
  );

  const metadata = data.sanityMetadata;

  const metaList = [
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
  ].concat(meta);

  if (metadata.title) {
    metaList.push(
      ...[
        {
          property: 'og:title',
          content: metadata.title,
        },
        {
          name: 'twitter:title',
          content: metadata.title,
        },
      ],
    );
  }

  if (metadata.description) {
    metaList.push(
      ...[
        {
          name: 'description',
          content: metadata.description,
        },
        {
          property: 'og:description',
          content: metadata.description,
        },
        {
          name: 'twitter:description',
          content: metadata.description,
        },
      ],
    );
  }

  if (metadata.url) {
    metaList.push({
      property: 'og:url',
      content: metadata.url,
    });
  }

  if (metadata.image) {
    metaList.push({
      property: 'og:image',
      content: metadata.image.asset.url,
    });
  }

  metadata.author = 'Mosca Digital';
  if (metadata.author) {
    metaList.push(
      ...[
        {
          name: 'author',
          content: metadata.author,
        },
        {
          name: 'twitter:creator',
          content: metadata.author,
        },
      ],
    );
  }

  if (metadata.facebook_app_id) {
    metaList.push({
      property: 'fb:app_id',
      content: metadata.facebook_app_id,
    });
  }

  return <Helmet title={metadata.title} meta={metaList} />;
}

export default SEO;
