import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

export default function Active(props: any) {
  return (
    <div className="project-details-item">
      <div className={'row' + (props.isImageInRight ? ' row-image-right' : ' row-image-left')}>
        <div className="project-details-info-wrapper">
          <div
            className={
              'project-details-info wow ' + (props.isImageInRight ? 'fadeInLeft' : 'fadeInRight')
            }
          >
            <h3 className="active-title">{props.activeTitle}</h3>
            <h3 className="project-details-title">Clever Red</h3>
            <h4>{props.title}</h4>
            <BlockContent className="project-details-descr" blocks={props.description} />
            <a className="project-url" href={props.url}>
              {props.url}
            </a>
          </div>
        </div>
        <div
          className={
            'project-details-img ' +
            (props.isImageInRight ? 'col-md-8 col-md-offset-4' : 'col-md-8')
          }
        >
          <img alt="" className="img-responsive" src={props.imageUrl} />
        </div>
      </div>
    </div>
  );
}
