import React, { useState } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import fetch from 'isomorphic-fetch';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/seo';
import MenuTopic from '../components/MenuTopic';
import ImageSlide from '../components/ImageSlide';
import TextSlide from '../components/TextSlide';
import Active from '../components/Active';
import VerticalPanel from '../components/VerticalPanel';
import './index.scss';
import './tablet.scss';
import './mobile.scss';

function encodeFormData(data: any) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

function renderTitle(title: string): React.ReactNode {
  const titleWords = title.trim().split(' ');
  const lastTitleWord = titleWords[titleWords.length - 1];
  const allTitleWordsButTheLast = titleWords.slice(0, titleWords.length - 1).join(' ');
  return (
    <>
      {allTitleWordsButTheLast} <span>{lastTitleWord}</span>
    </>
  );
}

function ProjectCard(props: any) {
  const { project } = props;
  return (
    <div className="project project-light">
      <a
        href={project.backgroundImage.asset.url}
        title={project.name}
        data-text={project.description}
      >
        {/* <p>{project.description}</p> */}
        <figure>
          <img className="projects-img" alt="" src={project.backgroundImage.asset.url} />
          <figcaption>
            <h3 className="project-title">{project.groupName}</h3>
            <span className="project-location">{project.name}</span>
            <h4 className="project-category">{project.type}</h4>
            <div className="project-zoom" />
          </figcaption>
        </figure>
        {/* <p>{project.description}</p> */}
      </a>
    </div>
  );
}

const IndexPage = () => {
  const data = useStaticQuery(
    graphql`
      {
        sanitySite {
          logos {
            verticalImage {
              ...ImageUrl
            }
            horizontalImage {
              ...ImageUrl
            }
            horizontalWithSloganImage {
              ...ImageUrl
            }
          }
          menu {
            home
            aboutUs
            ourHistory
            actives
            contacts
          }
          homeSection {
            slogan
            socialMedia {
              facebookUrl
              linkedInUrl
            }
            slides {
              ... on SanityTextSlide {
                text
                background {
                  ...ImageUrl
                }
              }
              ... on SanityImageSlide {
                image {
                  ...ImageUrl
                }
                background {
                  ...ImageUrl
                }
              }
            }
          }
          aboutSection {
            title
            intro
            image {
              ...ImageUrl
            }
          }
          _rawAboutSection(resolveReferences: { maxDepth: 1 })
          projectsSection {
            title
            projects {
              backgroundImage {
                ...ImageUrl
              }
              groupName
              name
              description
              type
            }
          }
          activesSection {
            title
            actives {
              image {
                ...ImageUrl
              }
              title
              url
            }
          }
          _rawActivesSection
          contactsSection {
            title
            address
            phone
            email
          }
        }
      }

      fragment ImageUrl on SanityImage {
        asset {
          url
        }
      }
    `,
  );

  const {
    logos,
    menu,
    homeSection,
    aboutSection,
    _rawAboutSection,
    projectsSection,
    activesSection,
    _rawActivesSection,
    contactsSection,
  } = data.sanitySite;

  const navigationBar = [
    { anchor: 'home', name: menu.home },
    { anchor: 'about', name: menu.aboutUs },
    { anchor: 'our-history', name: menu.ourHistory },
    { anchor: 'actives', name: menu.actives },
    { anchor: 'contacts', name: menu.contacts },
  ];

  const designImages = {
    waterMarkWhite: 'images/redWhite.png',
    waterMarkGray: 'images/redGray.png',
  };

  const serializers = {
    marks: {
      link: (props: any) => {
        return (
          <a href={props.mark.href} target="_blank">
            {props.children}
          </a>
        );
      },
    },
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [formError, setFormError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const hasErrors = nameError || emailError || phoneError || messageError || formError;

  const onSubmit = async (event: any) => {
    event.preventDefault();
    if (submitted || submitting) {
      return;
    }

    setSubmitting(true);

    let nameError = '';
    let emailError = '';
    let phoneError = '';
    let messageError = '';

    if (!name.trim()) {
      nameError = 'O campo Nome é obrigatório.';
    }
    if (!email.trim()) {
      emailError = 'O campo Email é obrigatório.';
    } else if (!email.match(/^\S+@\S+\.\S+$/)) {
      emailError = 'Email inválido (e.g. email@example.com)';
    }
    if (!phone.trim()) {
      phoneError = 'O campo Telefone é obrigatório.';
    }
    // if (!message.trim()) {
    //   messageError = 'Este campo é obrigatório.';
    // }

    setNameError(nameError);
    setEmailError(emailError);
    setPhoneError(phoneError);
    setMessageError(messageError);

    if (!nameError && !emailError && !phoneError && !messageError) {
      try {
        await fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encodeFormData({
            'form-name': 'contact',
            ...{
              name,
              email,
              phone,
              company,
              message,
            },
          }),
        });
        setSubmitted(true);
        setName('');
        setEmail('');
        setPhone('');
        setCompany('');
        setMessage('');
        // if (window.dataLayer) {
        //   window.dataLayer.push({ event: 'form_submitted' });
        // }
      } catch (error) {
        if (error && error.message === 'Failed to fetch') {
          setFormError('Ocorreu um erro de rede. Por favor, tente mais tarde.');
        } else {
          setFormError('Ocorreu um erro desconhecido. Por favor, tente mais tarde.');
        }
      }
    }
    setSubmitting(false);
  };

  return (
    <div>
      {/* Header */}
      <SEO />
      <header id="top" className="header-home">
        <div className="brand-panel">
          <img className="brand-panel-logo" src={logos.verticalImage.asset.url} alt="" />
          <div className="water-mark-wrapper">
            <img className="water-mark" src={designImages.waterMarkWhite} alt="" />
          </div>
        </div>
        <div className="vertical-panel" />
        <VerticalPanel
          slogan={homeSection.slogan}
          facebookUrl={homeSection.socialMedia && homeSection.socialMedia.facebookUrl}
          linkedInUrl={homeSection.socialMedia && homeSection.socialMedia.linkedInUrl}
        ></VerticalPanel>

        {/* Navigation Desctop */}
        <nav className="navbar-desctop visible-md visible-lg">
          <div className="container">
            <a href="#top" className="brand js-target-scroll">
              <img src={logos.horizontalImage.asset.url} alt="" />
            </a>
            <ul className="navbar-desctop-menu">
              {navigationBar.map((menuTopic, i) => (
                <MenuTopic key={i} anchor={menuTopic.anchor} name={menuTopic.name}></MenuTopic>
              ))}
            </ul>
          </div>
        </nav>
        {/* Navigation Mobile */}
        <nav className="navbar-mobile">
          <a href="#top" className="brand js-target-scroll">
            <img src={logos.horizontalImage.asset.url} alt="" />
          </a>
          {/* Navbar Collapse */}
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar-mobile"
          >
            <span className="sr-only">Abrir e fechar menu</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <div className="collapse navbar-collapse" id="navbar-mobile">
            <ul className="navbar-nav-mobile">
              {navigationBar.map((menuTopic, i) => (
                <MenuTopic key={i} anchor={menuTopic.anchor} name={menuTopic.name}></MenuTopic>
              ))}
            </ul>
          </div>
        </nav>
      </header>
      {/* Layout */}
      <div className="layout">
        {/* Home */}
        <div id="home" className="main">
          <div className="arrow-left" />
          <div className="arrow-right" />
          {/* Start revolution slider */}
          <div className="rev_slider_wrapper">
            <div id="rev_slider" className="rev_slider fullscreenbanner">
              <ul>
                {homeSection.slides.map((slide: any, i: number) =>
                  slide.hasOwnProperty('image') ? (
                    <ImageSlide
                      key={i}
                      backgroundUrl={slide.background.asset.url}
                      imageUrl={slide.image.asset.url}
                    ></ImageSlide>
                  ) : (
                    <TextSlide
                      key={i}
                      backgroundUrl={slide.background.asset.url}
                      text={slide.text}
                    ></TextSlide>
                  ),
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="content">
          {/* About  */}
          <section id="about" className="about section">
            <div id="about-anchor" className="anchor"></div>
            <div className="container">
              <header className="section-header">
                <h2 className="section-title">{renderTitle(aboutSection.title)}</h2>
              </header>
              <div className="section-content">
                <img className="watermark-left" src={designImages.waterMarkGray} alt="" />
                <div className="row-base row">
                  <div className="col-base col-sm-6 col-md-4">
                    <h3 className="col-about-title">{aboutSection.intro}</h3>
                    <div className="col-about-info"></div>
                  </div>
                  <div className="col-base col-about-spec col-sm-6 col-md-4">
                    <BlockContent blocks={_rawAboutSection.text} serializers={serializers} />
                  </div>
                  <div className="clearfix visible-sm" />
                  <div className="col-base col-about-img col-sm-6 col-md-4">
                    <img alt="" className="img-responsive" src={aboutSection.image.asset.url} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Projects */}
          <section id="our-history" className="projects section">
            <div id="our-history-anchor" className="anchor"></div>
            <div className="container">
              <h2 className="section-title">{renderTitle(projectsSection.title)}</h2>
            </div>
            <div className="section-content">
              <div className="projects-carousel js-projects-carousel js-projects-gallery">
                {projectsSection.projects.map((project: any, i: number) => (
                  <ProjectCard key={i} project={project}></ProjectCard>
                ))}
              </div>
            </div>
          </section>

          {/* Actives */}
          <section id="actives" className="project-details">
            <div id="actives-anchor" className="anchor"></div>
            <div className="container">
              {activesSection.actives.map((active: any, i: number) => (
                <Active
                  key={i}
                  isImageInRight={i % 2 === 0}
                  activeTitle={activesSection.title}
                  title={active.title}
                  description={_rawActivesSection.actives[i].description}
                  url={active.url}
                  imageUrl={active.image.asset.url}
                ></Active>
              ))}
            </div>
          </section>

          {/* Contacts */}
          <section id="contacts" className="contacts section">
            <div id="contacts-anchor" className="anchor"></div>
            <div className="container">
              <header className="section-header">
                <h2 className="section-title">{renderTitle(contactsSection.title)}</h2>
              </header>
              <div className="section-content">
                <div className="row-base row">
                  <div className="col-address col-base col-md-4">
                    <div>Morada: {contactsSection.address}</div>
                    <div>
                      Telefone: <a href={'tel:' + contactsSection.phone}>{contactsSection.phone}</a>
                    </div>
                    <div>
                      Email: <a href={'mailto:' + contactsSection.email}>{contactsSection.email}</a>
                    </div>
                  </div>

                  {/* ERRO NO INPUT*/}

                  <div className="col-base  col-md-8">
                    <form name="contact" data-netlify="true" data-netlify-honeypot="hidden-field">
                      <div className="row-field row">
                        <div className="col-field col-sm-6 col-md-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className={'form-control' + (nameError ? ' error wobble-error' : '')}
                              name="name"
                              placeholder="Nome"
                              value={name}
                              onChange={event => {
                                // @ts-ignore
                                setName(event.target.value);
                                setNameError('');
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="email"
                              className={'form-control' + (emailError ? ' error wobble-error' : '')}
                              name="email"
                              required
                              placeholder="Email"
                              value={email}
                              onChange={event => {
                                // @ts-ignore
                                setEmail(event.target.value);
                                setEmailError('');
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-field col-sm-6 col-md-4">
                          <div className="form-group">
                            <input
                              type="tel"
                              className={'form-control' + (phoneError ? ' error wobble-error' : '')}
                              name="phone"
                              placeholder="Telefone"
                              value={phone}
                              onChange={event => {
                                // @ts-ignore
                                setPhone(event.target.value);
                                setPhoneError('');
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="company"
                              placeholder="Empresa"
                              value={company}
                              onChange={event => {
                                // @ts-ignore
                                setCompany(event.target.value);
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-field col-sm-12 col-md-4">
                          <div className="form-group">
                            <textarea
                              className={
                                'form-control' + (messageError ? ' error wobble-error' : '')
                              }
                              name="message"
                              placeholder="Mensagem"
                              value={message}
                              onChange={event => {
                                // @ts-ignore
                                setMessage(event.target.value);
                                setMessageError('');
                              }}
                            />
                          </div>
                        </div>

                        {/* MENSAGENS DE ERRO */}

                        {submitted || hasErrors ? (
                          <div
                            className="col-message col-field col-sm-12"
                            style={{ display: 'block' }}
                          >
                            <div className="form-group">
                              {submitted && (
                                <div className="success-message" style={{ display: 'block' }}>
                                  <i className="fa fa-check text-primary" /> Muito obrigada! A sua
                                  mensagem foi enviada com sucesso.
                                </div>
                              )}
                              {hasErrors && (
                                <div className="error-message" style={{ display: 'block' }}>
                                  {nameError && (
                                    <div>
                                      <i className="fa fa-exclamation-triangle text-primary" />{' '}
                                      {nameError}
                                    </div>
                                  )}
                                  {emailError && (
                                    <div>
                                      <i className="fa fa-exclamation-triangle text-primary" />{' '}
                                      {emailError}
                                    </div>
                                  )}
                                  {phoneError && (
                                    <div>
                                      <i className="fa fa-exclamation-triangle text-primary" />{' '}
                                      {phoneError}
                                    </div>
                                  )}
                                  {messageError && (
                                    <div>
                                      <i className="fa fa-exclamation-triangle text-primary" />{' '}
                                      {messageError}
                                    </div>
                                  )}
                                  {formError && (
                                    <div>
                                      <i className="fa fa-exclamation-triangle text-primary" />{' '}
                                      {formError}
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>

                      <div className="form-submit text-right">
                        <button
                          type="submit"
                          className="btn btn-shadow-2 wow swing"
                          onClick={onSubmit}
                        >
                          Enviar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Footer */}
          <footer id="footer" className="footer">
            <div className="container">
              <div className="row-base row">
                <div className="col-base text-left-md col-md-6">
                  <img
                    className="footer-logo"
                    src={logos.horizontalWithSloganImage.asset.url}
                    alt=""
                  />
                </div>
                <div className="col-base text-right-md col-md-6">
                  &copy; 2020 | All Rights Reserved | Powered by{' '}
                  <a href="https://moscadigital.pt/?utm_source=cleverred.pt" target="_blank">
                    Mosca Digital
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
